@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  // Color
  --primary: #fff;
  --hover-color: #ACACAC;
  --primary-color:#131620;
  // Layout
  // --defaulf-layout-width: 1280px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto Mono', monospace;
  font-size: 1.6rem;
  text-rendering: optimizeSpeed;
  color: var(--primary);
  position: relative;
  margin: 0 auto;
}

.background-main {
  background-image: url('../../assets/images/background/background-main-page.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.background {
  background-image: url('../../assets/images/background/background-BoD.png');
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center;
}

.no-scroll {
  overflow: hidden;
}

a[href] {
  text-decoration: none;
  color: var(--primary);

  &:hover {
    color: var(--primary);
  }
}

.wrapper-icon {
  position: relative;
  width: 36px;
  height: 36px;
  color: var(--primary);
  background-color: #2641a6;
  border-radius: 50%;

  .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 6px;
    left: 6px;
    transition: all 0.4s ease 0s;

    &:hover {
      opacity: 0.5;
    }
  }
}

.heading-div {
  text-align: center;
  margin-top: 150px;
  margin-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-heading {
    width: 70%;
    text-align: center;
    h1 {
      font-size: 70px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
}

.breadcrumb {
  display: block;
  margin-bottom: 70px;
  
  .list-unstyled {
      display: flex;
      gap: 16px;

      li {
          transition: .3s;

          .item-link {
              color: var(--primary);
          }

          .department-active {
              font-weight: 600;
          }

          &:first-child:hover {
              scale: 1.2;
          }

          &:hover {
              opacity: .8;
          }
      }
  }
}

@media screen and (max-width: 1200px) {
  .heading-div {
    .content-heading {
      width: 70%;
      h1 {
        font-size: 60px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .heading-div {
    .content-heading {
      width: 75%;
      h1 {
        font-size: 55px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .heading-div {
    .content-heading {
      width: 85%;
      h1 {
        font-size: 45px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .heading-div {
    .content-heading {
      width: 90%;
      h1 {
        font-size: 35px;
      }
    }
  }
}

.card-body {
  color: #131620;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #475569;
  border-radius: 12px;
}